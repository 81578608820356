<template>
  <div>
    <mds-row
      align-horizontal="space-between"
      class="filter"
    >
      <mds-col
        :cols="12"
        class="filter__status"
      >
        <mds-fieldset
          horizontal
          class="filter__fieldset"
        >
          <span class="filter__label">{{ this.$t(`history.lastExecutionDate`) }}</span>
          <mds-combo-box
            v-model="parsersSelected"
            :label="this.$t(`history.parserLabel`)"
            class="filter__parsers"
            :data-set="parsers"
            @input="setParam($event)"
          />
          <mds-date-picker
            class="filter__startdate"
            :value="startDate"
            :min-max-dates="minMaxDates"
            :label="this.$t(`history.startDateLabel`)"
            required
            :error="startDateError"
            :error-text="[this.$t(`history.startDateError`)]"
            @input="updateCurrentDate"
          />
          <mds-date-picker
            v-model="endDate"
            class="filter__enddate"
            :min-max-dates="minMaxEndDates"
            :disable-dates="disableDates"
            :label="this.$t(`history.endDateLabel`)"
            required
            :error="endDateError"
            :error-text="[this.$t(`history.endDateError`)]"
            @input="endDateError = false"
          />
          <mds-button-group
            :content="buttonGroupContent"
            class="filter__status"
            @mds-button-group-item-active="setActiveItem"
          />
        </mds-fieldset>
      </mds-col>
    </mds-row>
    <mds-row
      align-horizontal="space-between"
      class="filter"
    >
      <mds-col
        :cols="12"
        class="filter__buttons"
      >
        <mds-button
          variation="secondary"
          text="Clear"
          class="filter__button"
          @click="clearParams"
        />
        <mds-button
          variation="primary"
          :text="this.$t(`history.searchButton`)"
          class="filter__button"
          :disabled="startDateError || endDateError"
          @click="validateFilters"
        />
      </mds-col>
    </mds-row>
  </div>
</template>

<script>
import MdsComboBox from '@mds/combo-box';
import { MdsButton } from '@mds/button';
import MdsButtonGroup from '@mds/button-group';
import MdsDatePicker from '@mds/date-picker';
import MdsFieldset from '@mds/fieldset';
import { MODULE, COMMON } from '@/constants/store.constant';
import { getParsers } from '@/services/api/exchange-monitors.service';
import { formatDateToYMD } from '@/utils/global.util';

const minMaxDates = {
  min: new Date(2015, 0, 1),
  max: new Date(2025, 8, 15),
};

export default {
  name: 'HistoryFilterSection',
  components: {
    MdsComboBox,
    MdsButton,
    MdsButtonGroup,
    MdsDatePicker,
    MdsFieldset,
  },
  data () {
    return {
      minMaxDates,
      minMaxEndDates: {
        ...minMaxDates,
      },
      disableDates: () => false,
      buttonGroupContent: [
        { id: '0', text: 'All', value: null, active: true },
        { id: '1', text: 'Completed', value: 'COMPLETED', active: false },
        { id: '2', text: 'Failed', value: 'FAILED', active: false },
        { id: '3', text: 'Stopped', value: 'STOPPED', active: false }
      ],
      parsers: [],
      parsersSelected: [],
      startDateError: false,
      endDateError: false,
    };
  },
  computed: {
    filterText: {
      get: function () {
        return this.$store.getters[`${MODULE.HISTORY_EXCHANGE_MONITOR}/${COMMON.GET_STATE_BY_NAME}`]('filterText') ?? '';
      },
      set: function (newValue) {
        this.$store.commit(`${MODULE.HISTORY_EXCHANGE_MONITOR}/${COMMON.UPDATE_STATE_PAYLOAD}`, { filterText: newValue });
        this.$store.commit(`${MODULE.HISTORY_EXCHANGE_MONITOR}/${COMMON.UPDATE_PARAMS}`, { parserId: newValue });
      },
    },
    startDate: {
      get: function () {
        return this.$store.getters[`${MODULE.HISTORY_EXCHANGE_MONITOR}/${COMMON.GET_STATE_BY_NAME}`]('startDate');
      },
      set: function (newValue) {
        const intervalFrom = newValue && `${formatDateToYMD(newValue)}T00:00:00.000Z`;
        this.$store.commit(`${MODULE.HISTORY_EXCHANGE_MONITOR}/${COMMON.UPDATE_STATE}`, { name: 'startDate', value: newValue });
        this.$store.commit(`${MODULE.HISTORY_EXCHANGE_MONITOR}/${COMMON.UPDATE_PARAM}`, { name: 'intervalFrom', value: intervalFrom });
      },
    },
    endDate: {
      get: function () {
        return this.$store.getters[`${MODULE.HISTORY_EXCHANGE_MONITOR}/${COMMON.GET_STATE_BY_NAME}`]('endDate');
      },
      set: function (newValue) {
        const intervalTo = newValue && `${formatDateToYMD(newValue)}T00:00:00.000Z`;
        this.$store.commit(`${MODULE.HISTORY_EXCHANGE_MONITOR}/${COMMON.UPDATE_STATE}`, { name: 'endDate', value: newValue });
        this.$store.commit(`${MODULE.HISTORY_EXCHANGE_MONITOR}/${COMMON.UPDATE_PARAM}`, { name: 'intervalTo', value: intervalTo });
      },
    },
    filterStatus: {
      get: function () {
        return this.$store.getters[`${MODULE.HISTORY_EXCHANGE_MONITOR}/${COMMON.GET_STATE_BY_NAME}`]('filterStatus') ?? '';
      },
      set: function (newValue) {
        this.$store.commit(`${MODULE.HISTORY_EXCHANGE_MONITOR}/${COMMON.UPDATE_STATE_PAYLOAD}`, { filterStatus: newValue });
        this.$store.commit(`${MODULE.HISTORY_EXCHANGE_MONITOR}/${COMMON.UPDATE_PARAM}`, { name: 'status', value: newValue });
      },
    },
  },
  created () {
    this.getParsers();
  },
  methods: {
    async getParsers () {
      const { data: { content: parsers } } = await getParsers({ params: { size: 200 } });
      this.parsers = parsers.map((parser) => {
        return {
          text: parser.displayName,
          value: parser.id,
        };
      });
    },
    setParam (value) {
      this.filterText = value[0] || null;
    },
    checkValidDate (date) {
      return (
        !!date &&
            typeof date.getTime === 'function' &&
            date.toString() !== 'Invalid Date'
      );
    },
    updateCurrentDate (value) {
      this.startDate = value;
      this.startDateError = false;
      this.endDate = '';

      if (this.checkValidDate(value)) {
        this.disableDates = (date) => date.getTime() <= value.getTime();
        this.minMaxEndDates.min = value;
      } else {
        this.disableDates = () => false;
      }
    },
    applyFilters () {
      this.$store.commit(`${MODULE.HISTORY_EXCHANGE_MONITOR}/${COMMON.UPDATE_STATE_PAYLOAD}`, { initialFetch: true, shouldRefresh: true });
    },
    clearParams () {
      this.parsersSelected = [];
      this.$store.commit(`${MODULE.HISTORY_EXCHANGE_MONITOR}/${COMMON.UPDATE_STATE_PAYLOAD}`, { startDate: null, endDate: null, filterText: null, filterStatus: null, initialFetch: false, totalElements: 0 });
      this.$store.commit(`${MODULE.HISTORY_EXCHANGE_MONITOR}/${COMMON.RESET_PARAMS}`);
    },
    setActiveItem ({ currentTarget: { id } }) {
      this.buttonGroupContent.forEach((item) => {
        item.active = (item.id === id);
        if (item.active) {
          this.filterStatus = item.value;
        };
      });
    },
    validateFilters () {
      if (!this.startDate) {
        this.startDateError = true;
      }
      if (!this.endDate) {
        this.endDateError = true;
      }
      if (!this.startDateError && !this.endDateError) {
        this.applyFilters();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@mds/typography';
.filter {
  &__search {
    width: 280px;
    margin-top: $mds-space-3-x;
  }
  &__button {
    margin-top: $mds-space-2-and-a-half-x;
    margin-left: $mds-space-2-x;
    display: block;
    max-width: 90px;
  }
  &__fieldset {
    border: solid 1px #e5e5e5;
    border-radius: $mds-space-quarter-x;
    box-sizing: border-box;
    position: relative;
    padding: $mds-space-4-x $mds-space-2-x $mds-space-2-x;
  }
  &__startdate {
    margin-left: $mds-space-2-and-a-half-x;
  }
  &__enddate {
    margin-left: $mds-space-2-and-a-half-x;
  }
  &__status {
    margin-top: $mds-space-2-and-a-half-x;
    margin-left: $mds-space-2-and-a-half-x;
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
  }
  &__label {
    @include mds-body-text-m();
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1.67px;
    line-height: $mds-space-1-and-a-half-x;
    text-transform: uppercase;
    color: #1e1e1e;
    padding: $mds-space-quarter-x $mds-space-half-x;
    position: absolute;
    top: $mds-space-1-x;
  }
}
</style>
