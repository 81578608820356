export const TABLE_HEADERS = [
  {
    fieldName: 'name',
    text: 'Name',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'profileId',
    text: 'Profile Uuid',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'batchId',
    text: 'Batch Id',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'createdAt',
    text: 'Process Start Time (UTC)',
    style: {
      width: '18%',
    },
  },
  {
    fieldName: 'lastExecutedDate',
    text: 'Process End Time (UTC)',
    style: {
      width: '18%',
    },
  },
  {
    fieldName: 'crawlingTimeframeStart',
    text: 'Crawling Start Time (UTC)',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'status',
    text: 'Status',
    style: {
      width: '7%',
    },
  },
  {
    fieldName: 'stage',
    text: 'Stage',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'foundFiles',
    text: 'S3 Files',
    style: {
      width: '6%',
    },
  },
  {
    fieldName: 'numBrokenLinks',
    text: 'Errors',
    style: {
      width: '6%',
    },
  },
  {
    fieldName: 'url',
    text: 'S3 Report',
    style: {
      width: '6%',
    },
  },
  {
    fieldName: 'actions',
    text: 'Full detail',
    style: {
      width: '10%',
    },
  }
];
